import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as IconGetCoins } from '../Icons/GetCoins.svg';
import { ReactComponent as IconClose } from '../Icons/Close.svg';
import './styles.scss';
import { Button } from '../UI/Button';
import { hideInstallAppModal } from '../../store/actions/sessionActions';
import { isIOS, isAndroid } from 'react-device-detect';
import Axios from 'axios';
import __ from '../../utils/translate';

const GetFreeCoins = () => {
  const dispatch = useDispatch();
  const installApp = () => {
    if (isIOS) {
      Axios.post(
        `/endpoints/track-lead-from-web`,
      ).then((response) => {
        console.log(response)
        if (response.data.result === 'success') {
          dispatch(hideInstallAppModal())
          window.location.href = 'https://apps.apple.com/app/id1523632672';
        }
      });
    }

    if (!isIOS && !isAndroid) {
      Axios.post(
        `/endpoints/track-lead-from-web`,
      ).then((response) => {
        console.log(response)
        if (response.data.result === 'success') {
          dispatch(hideInstallAppModal())
        }
      });
    }

    if (isAndroid) {
      Axios.post(
        `/endpoints/track-lead-from-web`,
      ).then((response) => {
        if (response.data.result === 'success') {
          dispatch(hideInstallAppModal())
          window.location.href = 'https://play.google.com/store/apps/details?id=com.loka';
        }
      });
    }
  };

  return <>
    <div className="get-free-coins">
      <div className="get-free-coins__container">
        <div className="get-free-coins__cancel" onClick={() => dispatch(hideInstallAppModal())}><IconClose/></div>
        <IconGetCoins/>
        <div className="get-free-coins__title">
          <p>{__('Get even more free Coins')}</p>
        </div>
        <div className="get-free-coins__text">
          <p>{__('Install our app and get more free Coins. Easy as pie!')}</p>
        </div>
        <Button class="install_btn" onClick={() => installApp()}>{__('Install Loka App!')}</Button>
      </div>
    </div>
  </>;
};
export { GetFreeCoins };