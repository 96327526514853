import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Scrollbars} from 'react-custom-scrollbars';
import Axios from 'axios';
import {Input} from '../../../UI';
import __ from '../../../../utils/translate';
import {ReactComponent as IconSelectedCheck} from '../../../Icons/SelectedCheck.svg';
import { setSearchParams } from '../../../../store/actions/peopleSearchActions';

const Location = ({selectLocation, formControl = false, showAllSettings}) => {
  const [cityName, setCityName] = useState('');
  const [locations, setLocations] = useState([]);
  const {formatted_address} = useSelector(state => state.peopleSearch)
  const dispatch = useDispatch()

  useEffect(() => {
    setCityName(formatted_address);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = (searchPhrase) => {
    setCityName(searchPhrase);
    Axios.get(
      `/location?query=${searchPhrase}&search_countries=1&use_geo=0&sort=user`,
    ).then((response) => {
      setLocations(response.data.locations);
    });
  };

  const selectItem = (item) => {
    const objLocation = {
      country_id: item.country_id,
      region_id: item.region_id,
      city_id: item.city_id,
      formatted_address: item.formatted_address
    };
    setCityName(item.formatted_address);
    setLocations([]);
    selectLocation(objLocation);
  };

  const renderItems = () => {
    if (!cityName || (cityName && cityName.length < 1)) {
      return null;
    }

    const row = (item, index) => {
      return (
        <div
          className="select-menu__item"
          onClick={() => selectItem(item)}
          key={`${index}`}>
          <div>
            <div className="select-menu__value" style={{textAlign: 'left'}}>
              {item.formatted_address}
            </div>
          </div>
          <div className="select-menu__check">
            <IconSelectedCheck />
          </div>
        </div>
      );
    };

    return locations && locations.length ? (
      <div className="location_select-menu select-menu">
        <Scrollbars autoHeight autoHeightMin={20} autoHeightMax={'200px'}>
          {locations.map((item, index) => row(item, index))}
        </Scrollbars>
      </div>
    ) : null;
  };

  const resetLocation = () => {
    setCityName('');
    dispatch(setSearchParams({
      country_id: '',
      region_id: '',
      city_id: '',
      formatted_address: '',
    }))
    showAllSettings()
  }
  return (
    <div>
      <div className="form_label form_label_wrapper">
        <span>{__('Location')}</span>
        {
          cityName.length
            ? <button className="btn_link btn_filter" onClick={resetLocation}>{__('Reset location')}</button>
            : null
        }
      </div>
      <Input
        extraСlass={formControl ? 'mb20' : null}
        invalid={false}
        error={false}
        value={cityName || ''}
        onChange={(event) => handleOnChange(event.target.value)}
        placeholder={__('Enter city name')} // localized
        autoFocus={true}
      />
      {renderItems()}
    </div>
  );
};

export default Location;
