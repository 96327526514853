import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as bindAuthActions from '../../../store/actions/auth/authActions';
import * as bindLogoutActions from '../../../store/actions/auth/logoutActions';
import queryString from 'query-string';
import __ from '../../../utils/translate';
import { Notification } from '../../../Components/UI/Notification';
import {autologinSecret} from "../../../store/actions/auth/authActions";

class AutoLogin extends Component {
  state = {
    unsubscribeModal: false
  }

  componentDidMount() {
    const {logoutActions, credentials} = this.props;

    if (credentials.user_id) {
      logoutActions.logout().then(() => setTimeout(() => this.autoReadQuery(), 1000));
    } else {
      this.autoReadQuery();
    }
  }

  autoReadQuery() {
    const {location, authActions} = this.props;
    const params = queryString.parse(location.search);

    if (params.secret) {
      console.log('params.secret', params)
      authActions
          .autologinSecret(params.secret)
          .then(() => {
            setTimeout(() => this.props.history.push({pathname: '/get-premium'}), 4000)
            setTimeout(() => window.location.reload(), 5000)
          });
      return;
    }

    switch (params.secret) {
      case '/lalh':
        if (params.token_l && params.url) {
          authActions
            .autologin(params.token_l)
            .then(() => this.redirectToURL(params.url));
        }
        break;
      case '/calh':
        if (params.token_l && params.token_c && params.url) {
          authActions
            .autologin(params.token_l)
            .then(() => authActions.trackEmail(params.token_c))
            .then(() => this.redirectToURL(params.url));
        }
        break;
      case '/ualh':
        if (params.token_l && params.token_u) {
          authActions
            .autologin(params.token_l)
            .then(() => authActions.unsubscribe(params.token_u))
            .then(() => {
              this.setState({ unsubscribeModal: true })
            });
        }
        break;
      default:
    }
  }

  redirectToURL(url) {
    if (url.indexOf('/chat') > -1) {
      const splittedUrl = url.split('/');
      const id = splittedUrl[splittedUrl.length - 1];
      const chatId = parseInt(id, 10);
      if (typeof chatId === 'number') {
        this.props.history.push({pathname: '/messages', state: {chatId}});
      }
      return;
    }
    if (url.indexOf('/chat') > -1) {
      const splittedUrl = url.split('/');
      const id = splittedUrl[splittedUrl.length - 1];
      const chatId = parseInt(id, 10);
      if (typeof chatId === 'number') {
        this.props.history.push({pathname: '/messages', state: {chatId}});
      }
      return;
    }
    this.props.history.push({pathname: url, state: {isAutoLogin: true}});
  }

  render() {
    return this.state.unsubscribeModal ? (
      <Notification
            textLine1={__('You have been successfully unsubscibed.')}
            textButton={__('Done')}
            title={__('Success!')}
            close={() => this.props.history.push('/')}
          />
    ) : null;
  }
}

export default withRouter(
  connect(
    (state) => ({
      registration: state.registration,
      credentials: state.credentials,
    }),
    (dispatch) => ({
      authActions: bindActionCreators(bindAuthActions, dispatch),
      logoutActions: bindActionCreators(bindLogoutActions, dispatch),
    }),
  )(AutoLogin),
);
