import React, {useEffect, useRef, useState} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {ReactComponent as IconSelectedCheck} from '../../Icons/SelectedCheck.svg';
import {ReactComponent as LLIconSelectedCheck} from '../../Icons/loveleboard/SelectedCheck.svg';
import {ReactComponent as ArrowDown} from '../../Icons/ArrowDown.svg';
import __ from '../../../utils/translate';
import './style.scss';
import {Button} from '../../UI';
import {setSearchParams} from '../../../store/actions/peopleSearchActions';
import {useDispatch, useSelector} from 'react-redux';
import {isLoveLeboard} from '../../../apps-config';

const SearchSettingsLanguages = ({languages, showAllSettings}) => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectMenu, setSelectMenu] = useState(false);
  const showSelectMenu = () => {
    setSelectMenu(!selectMenu);
  };
  const {languages: selectedLang} = useSelector((state) => state.peopleSearch);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedLanguages(
      selectedLang.map((lang) => languages.find((it) => it.id === lang)),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setLanguage = (lang) => {
    console.log('lang', lang);
    const cLanguages = [...selectedLanguages];
    const exist = cLanguages.filter((it) => it.id === lang.id)[0];
    if (exist) {
      cLanguages.splice(
        cLanguages.indexOf(cLanguages.filter((it) => it.name === lang.name)[0]),
        1,
      );
    } else {
      cLanguages.push(lang);
    }
    setSelectedLanguages(cLanguages);
  };

  const useOutsideStoragesForm = (ref) => {
    useEffect(() => {
      /**
       * If clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSelectMenu(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideStoragesForm(wrapperRef);

  const saveHandlerLang = () => {
    dispatch(
      setSearchParams({
        languages: selectedLanguages.map((it) => it.id),
      }),
    );
    showAllSettings();
  };

  const resetLanguages = () => {
    dispatch(
      setSearchParams({
        languages: [],
      }),
    );
    setSelectedLanguages([]);
    showAllSettings();
  };

  return (
    <div ref={wrapperRef}>
      <div className={'form_control mb20 search_settings_lang'}>
        <div className="form_label form_label_wrapper">
          <span>{__('Languages')}</span>
          {selectedLanguages && selectedLanguages.length ? (
            <button
              className="btn_link btn_filter"
              onClick={() => resetLanguages()}>
              {__('Reset languages')}
            </button>
          ) : null}
        </div>
        <div className="lang_select">
          <div className="lang_select-wrap form_input" onClick={showSelectMenu}>
            <div className="lang_selected">
              {selectedLanguages && selectedLanguages.length
                ? selectedLanguages.map((lang, i) => (
                    <span key={lang.id}>
                      {lang.name}
                      {i < selectedLanguages.length - 1 ? ', ' : null}
                    </span>
                  ))
                : null}
            </div>
            <ArrowDown />
          </div>
          {selectMenu ? (
            <div className="lang_select-menu select-menu">
              <p className="select-menu__title">{__('Languages')}</p>
              <Scrollbars autoHeight autoHeightMin={20} autoHeightMax={'200px'}>
                {languages && languages.length
                  ? languages.map((lang, i) => (
                      <div
                        className={
                          languages.filter(
                            (it) => selectedLanguages.indexOf(it.id) > -1,
                          )[i] !==
                          selectedLanguages.find((it) => it.id === lang.id)
                            ? 'select-menu__item active'
                            : 'select-menu__item'
                        }
                        key={lang.id}
                        onClick={(e) => {
                          e.preventDefault();
                          setLanguage(lang);
                        }}>
                        <div>
                          <div className="select-menu__value">{lang.name}</div>
                        </div>
                        <div className="select-menu__check">
                          {isLoveLeboard() ? (
                            <LLIconSelectedCheck />
                          ) : (
                            <IconSelectedCheck />
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </Scrollbars>
            </div>
          ) : null}
        </div>
      </div>
      <div className="save-settings-btn-wrapper">
        <Button onClick={saveHandlerLang}>{__('Save')}</Button>
      </div>
    </div>
  );
};

export default SearchSettingsLanguages;
