import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {ReactComponent as IconClose} from '../../Components/Icons/Close.svg';
import {ReactComponent as LLIconClose} from '../../Components/Icons/loveleboard/Close.svg';
import {bindActionCreators} from 'redux';
import * as bindPurchaseActions from '../../store/actions/purchaseActions';
import * as bindMyProfileActions from '../../store/actions/myProfileActions';
import * as bindEventsActions from '../../store/actions/eventsActions';
import __ from '../../utils/translate';
import './styles.scss';
import {declOfNum} from '../../helpers';
import {appSwitcher, isLoveLeboard} from '../../apps-config';
import {PAYMENT_TYPE, PUBLIC_KEY_CLOUDPAYMENTS} from '../../store/constants';
import modalRouteService from '../../services/ModalRouteService';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {isMobile} from 'react-device-detect';

class GetPremium extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      isLoading: false,
    };
  }

  handleOnClick = () => {
    this.props.eventsActions.closeModal(false);
  };

  componentDidMount() {
    modalRouteService.addPath(this.props.match.url);
    console.log(
      'this.props purchase',
      this.props.purchase.tariffs.subscriptions,
    );
  }

  goBack() {
    window.open('/add-coins', '_self');
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.goBack();
    }
  }

  choosePayment(plan_id, productId, productCurrency, productAmount, period) {
    console.log('period', period);
    if (this.props.credentials && this.props.credentials.user_id === 136810) {
      this.props.purchaseActions.getPaymentUrl(productId);
      this.props.history.push({
        pathname: '/get-payment',
        state: {modal: true, planId: plan_id, productId},
      });

      return;
    }

    switch (PAYMENT_TYPE) {
      case 'pw': {
        this.props.purchaseActions.getPaymentUrl(productId);
        this.props.history.push({
          pathname: '/get-payment',
          state: {modal: true, planId: plan_id, productId},
        });
        break;
      }
      case 'paypal': {
        this.props.history.push({
          pathname: '/get-payment',
          state: {modal: true, planId: plan_id, productId},
        });
        break;
      }
      case 'cloudpayments': {
        /*global cp*/
        const widget = new cp.CloudPayments();
        const receipt = {
          Items: [
            {
              label: 'Оплата в love.leboard', // наименование товара
              amount: +productAmount.toFixed(2), // сумма
              price: +productAmount.toFixed(2), // цена
              quantity: 1.0,
              vat: null, //ставка НДС
              method: 0, // тег-1214 признак способа расчета - признак способа расчета
              object: 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
              measurementUnit: 'шт', // declOfNum(+period, ['месяц', 'месяца', 'месяцев']) //единица измерения
            },
          ],
          isBso: false, // чек является бланком строгой отчетности
          taxationSystem: 1, //система налогообложения усн
          email: this.props.myProfile.email, // e-mail покупателя, если нужно отправить письмо с чеком
          calculationPlace: 'www.leboard.ru', //Место осуществления расчёта
          amounts: {
            electronic: +productAmount.toFixed(2), // Сумма оплаты электронными деньгами
            advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
            credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
            provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
          },
        };
        const data = {
          user_id: this.props.credentials.user_id,
          tariff_id: productId,
        };
        data.cloudPayments = {
          Inn: '7722344290',
          Type: 'Income',
          CustomerReceipt: receipt, //чек для первого платежа
          recurrent: {
            interval: 'Month',
            period,
            customerReceipt: receipt, //чек для регулярных платежей
          },
        }; //создание ежемесячной подписки

        widget.charge(
          {
            // options
            publicId: PUBLIC_KEY_CLOUDPAYMENTS,
            description: 'Оплата в love.leboard',
            amount: productAmount,
            currency: productCurrency,
            email: this.props.myProfile.email,
            requireEmail: true,
            skin: 'modern',
            accountId: this.props.credentials.user_id,
            data: data,
          },
          (options) => {
            this.props.myProfileActions.loadMyProfile();
            this.goBack();
          },
          function (reason, options) {
            // fail
            //действие при неуспешной оплате
          },
        );
        break;
      }
      case 'stripe': {
        this.props.purchaseActions.stripeGetSession(productId);
        break;
      }
      default: {
        this.props.purchaseActions.getPaymentUrl(productId);
        this.props.history.push({
          pathname: '/get-payment',
          state: {modal: true, planId: plan_id, productId},
        });
      }
    }
  }

  countSave(product, tariffs) {
    let save;
    const priceByFirstMoth = tariffs.subscriptions.sort(function (a, b) {
      return a.value.month - b.value.month;
    })[0].amount_further;
    const priceByMonth = (+(product.amount / +product.value.month)).toFixed(2);
    save = (+(100 - (priceByMonth * 100) / priceByFirstMoth)).toFixed(0);
    return save;
  }

  render() {
    const {isFetching, tariffs} = this.props.purchase;
    const {isLoading} = this.state;
    const clsBody = appSwitcher(
      'popup_body popup-purchase',
      'popup_body popup-purchase ts-popup-purchase',
      'popup_body popup-purchase ll-popup-purchase',
    );
    return (
      <div className={clsBody}>
        <div className="popup-purchase__head">
          <div className="btn_close" onClick={this.goBack}>
            {isLoveLeboard() ? <LLIconClose /> : <IconClose />}
          </div>
          <h1 className={`popup-purchase__title ${isLoveLeboard() && 'popup-purchase__title__santa'}`}>
            {isLoveLeboard() ? __('Get Premium') : 'Станьте VIP пользователем'}
          </h1>
          <p className="popup-purchase__description">
            {__('Choose your plan and save.')}
            <br />
            {__('No commitments, cancel anytime.')}
          </p>
          <div
            style={{
              marginTop: 10,
              width: '100%',
              // height: 40,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div
              style={{
                width: isMobile ? '60%' : '30%',
                // height: 40,
                display: 'flex',
                justifyContent: 'center',
                // backgroundColor: 'red',
              }}>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                customLeftArrow={<div />}
                customRightArrow={<div />}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 2000,
                      min: 300,
                    },
                    items: 1,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable>
                <div
                  style={{
                    display: 'flex',
                    width: '50%',
                    height: 90,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: 10,
                  }}>
                  <img
                    src={'/img/ic-delete1.png'}
                    style={{
                      height: 30,
                      width: 30,
                    }}
                    alt=""
                  />
                  <p
                    style={{
                      color: 'black',
                      marginLeft: 20,
                      textAlign: 'left',
                    }}>
                    {isLoveLeboard()
                      ? __('Неограниченное\nчисло контактов')
                      : __('Unlimited new\ncontacts per day')}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '80%',
                    height: 70,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: 10,
                  }}>
                  <img
                    src="/img/ic-delete2.png"
                    style={{
                      height: 30,
                      width: 30,
                    }}
                    alt=""
                  />
                  <p
                    style={{
                      color: 'black',
                      marginLeft: 20,
                      textAlign: 'left',
                    }}>
                    {isLoveLeboard()
                      ? __('50 токенов в неделю')
                      : __('50 free Tokens\nevery week')}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '80%',
                    height: 70,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: 10,
                  }}>
                  <img
                    src="./img/ic-delete3.png"
                    style={{
                      height: 30,
                      width: 30,
                    }}
                    alt=""
                  />
                  <p
                    style={{
                      color: 'black',
                      marginLeft: 20,
                      textAlign: 'left',
                    }}>
                    {isLoveLeboard()
                      ? __('5 токенов за заход в день')
                      : __('5 tokens per login per day')}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '50%',
                    height: 70,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: 10,
                  }}>
                  <img
                    src="./img/ic-delete3.png"
                    style={{
                      height: 30,
                      width: 30,
                    }}
                    alt=""
                  />
                  <p
                    style={{
                      color: 'black',
                      marginLeft: 20,
                      textAlign: 'left',
                    }}>
                    {isLoveLeboard()
                      ? __('Дополнительные фильтры')
                      : __('Additional filters')}
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="popup-purchase__content-sub popup_content scroll">
          {isFetching || isLoading
            ? __('Loading...')
            : tariffs.subscriptions && tariffs.subscriptions.length
            ? tariffs.subscriptions
                .sort(function (a, b) {
                  return a.value.month - b.value.month;
                })
                .map((product, i, products) => {
                  console.warn('product: ', product);
                  let sale = '';
                  if (
                    isLoveLeboard() &&
                    products[0] &&
                    product &&
                    product.value
                  ) {
                    sale = Math.round(
                      100 -
                        ((product.amount / product.value.month) * 100) /
                          products[0].amount_further,
                    );
                  }
                  return isLoveLeboard() ? (
                    <div className="ll-sub_btn_wrap">
                      <div
                        className="ll-sub_btn"
                        onClick={() =>
                          this.choosePayment(
                            product.plan_id,
                            product.id,
                            product.currency,
                            product.type === 'promo'
                              ? product.amount
                              : product.amount_further,
                            product.value.month,
                          )
                        }>
                          {isLoveLeboard() ? <>
                              {i === 0 && (
                                  <div
                                      className="ll-sub_btn_sale ll-sub_btn_sale-test"
                                      style={{maxWidth: '300px'}}>
                                      Только сегодня! Скидка 20%
                                  </div>
                              )}
                              {i === 1 && (
                                  <div
                                      className="ll-sub_btn_sale ll-sub_btn_sale-test"
                                      style={{maxWidth: '300px'}}>
                                      Скидка 33% + 20% сегодня!
                                  </div>
                              )}
                              {i === 2 && (
                                  <div
                                      className="ll-sub_btn_sale ll-sub_btn_sale-test"
                                      style={{maxWidth: '300px'}}>
                                      Скидка 44% + 20% сегодня!
                                  </div>
                              )}
                              {i === 3 && (
                                  <div
                                      className="ll-sub_btn_sale ll-sub_btn_sale-test"
                                      style={{maxWidth: '300px'}}>
                                      Скидка 61% + 20% сегодня!
                                  </div>
                              )}
                          </> : <>
                              {i !== 0 && (
                                  <div className="ll-sub_btn_sale">Скидка {sale}%</div>
                              )}
                              {i === 0 && product.type === 'promo' && (
                                  <div
                                      className="ll-sub_btn_sale ll-sub_btn_sale-test"
                                      style={{maxWidth: '300px'}}>
                                      Только сегодня! Скидка 20%
                                  </div>
                              )}
                          </>}
                        <p className="ll-sub_btn_month">
                          {product.value.month}{' '}
                          {isLoveLeboard()
                            ? declOfNum(product.value.month, [
                                __('Месяц'),
                                __('Месяца'),
                                __('Месяцев'),
                              ])
                            : declOfNum(product.value.month, [
                                __('Month'),
                                __('Months'),
                                __('Months'),
                              ])}
                        </p>
                        <p className="ll-sub_btn_price" style={{ marginTop: isLoveLeboard() ? 3 : 0 }}>
                            {isLoveLeboard() ? <>
                                <span
                                    style={{
                                        opacity: '0.5',
                                        fontWeight: 'normal',
                                        textDecoration: 'line-through',
                                        fontSize: 16
                                    }}>{`${i === 0 ? 1490 : ''}${i === 1 ? 997 : ''}${i === 2 ? 832 : ''}${i === 3 ? 583 : ''} ₽ /мес`}</span>
                                <br />
                                <>{`${Math.round(
                                    product.amount / product.value.month,
                                )} ₽ /мес`}</>
                            </> : <>
                                {i === 0 &&
                                    isLoveLeboard() &&
                                    product.type === 'promo' && (
                                        <>
                                            <span
                                                style={{
                                                    opacity: '0.5',
                                                    fontWeight: 'normal',
                                                    textDecoration: 'line-through',
                                                }}>{`${Math.round(
                                                product.amount_further / product.value.month,
                                            )} ₽ /мес`}</span>
                                            <br />
                                        </>
                                    )}
                                {isLoveLeboard()
                                    ? product.type === 'promo'
                                        ? `${Math.round(
                                            product.amount / product.value.month,
                                        )} ₽ /мес`
                                        : `${Math.round(
                                            product.amount_further / product.value.month,
                                        )} ₽ /мес`
                                    : `$ ${product.amount} /mo`}
                            </>}
                        </p>
                      </div>
                      <p className="ll-sub_btn_secondary">
                        {product.amount_further} ₽{' '}
                        {product.value.month !== 1 ? 'каждые ' : 'каждый'}
                        {product.value.month !== 1
                          ? product.value.month
                          : ''}{' '}
                        {declOfNum(product.value.month, [
                          __('месяц'),
                          __('мес.'),
                          __('мес.'),
                        ])}
                      </p>
                    </div>
                  ) : (
                    <>
                      {i === 0 && product.type === 'common' ? (
                        <div
                          key={i}
                          className="purchase-sub purchase-sub_trial">
                          <div
                            className="purchase-sub__container purchase-sub__container_trial"
                            onClick={() =>
                              this.choosePayment(
                                product.plan_id,
                                product.id,
                                product.currency,
                                product.amount,
                              )
                            }>
                            <div className="purchase-sub__month">
                              {product.value.month} {__('Month')}
                            </div>
                            <div className="purchase-sub__cost">
                              {product.currency === 'usd' ? '$' : null}
                              {product.amount}
                              {product.currency === 'eur' ? '€' : null} /mo
                            </div>

                            <div className="purchase-sub__benefit purchase-sub__benefit_trial">
                              <div className="purchase-sub__benefit-cost">
                                {__('Introductory price')} -
                              </div>
                              <div className="purchase-sub__benefit-dicount">
                                60% off
                              </div>
                            </div>
                          </div>

                          <p className="discount-text">
                            *{' '}
                            {__(
                              'first month discount. Automatically renews for $14.99/mo starting from the 2nd month.',
                            )}
                          </p>
                        </div>
                      ) : null}
                      {i === 0 && product.type === 'common' ? (
                        <div
                          key={i}
                          className="purchase-sub"
                          onClick={() =>
                            this.getPW(product.plan_id, product.id)
                          }>
                          <div className="purchase-sub__container">
                            <div className="purchase-sub__month">
                              {product.value.month} {__('Month')}
                            </div>
                            <div className="purchase-sub__cost">
                              {isLoveLeboard()
                                ? this.props.history.location.state.is_russia
                                  ? `${product.amount} ₽ /мес`
                                  : `$ ${product.amount} /mo`
                                : `$ ${product.amount} /mo`}
                            </div>

                            <div className="purchase-sub__benefit">
                              <div className="purchase-sub__benefit-cost">
                                $29.99 total -
                              </div>
                              <div className="purchase-sub__benefit-dicount">
                                Save 33%
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {i !== 0 ? (
                        <div
                          key={i}
                          className="purchase-sub"
                          onClick={() =>
                            this.getPW(product.plan_id, product.id)
                          }>
                          <div className="purchase-sub__container">
                            <div className="purchase-sub__month">
                              {product.value.month}{' '}
                              {declOfNum(product.value.month, [
                                __('Month'),
                                __('Months'),
                                __('Months'),
                              ])}
                            </div>
                            <div className="purchase-sub__cost">
                              {isLoveLeboard()
                                ? this.props.history.location.state.is_russia
                                  ? `${product.amount} ₽ /мес`
                                  : `$ ${product.amount} /mo`
                                : `$ ${product.amount} /mo`}
                            </div>

                            <div className="purchase-sub__benefit">
                              <div className="purchase-sub__benefit-cost">
                                $29.99 total -
                              </div>
                              <div className="purchase-sub__benefit-dicount">
                                {__('Save {{discount}}%', {discount: 33})}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })
            : 'error'}
        </div>
        <div className="popup-purchase__agree">
          <Link
            to={
              isLoveLeboard()
                ? {
                    pathname: 'https://leboard.ru/polzovatelskoe_soglashenie',
                  }
                : '/terms'
            }
            target={isLoveLeboard() ? '_blank' : null}
            onClick={() => this.handleOnClick()}>
            {__('Terms of Service {{and}} Privacy Policy', {
              and: <span>{__('and')}</span>,
            })}
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      purchase: state.purchase,
      credentials: state.credentials,
      myProfile: state.myProfile,
    }),
    (dispatch) => ({
      purchaseActions: bindActionCreators(bindPurchaseActions, dispatch),
      myProfileActions: bindActionCreators(bindMyProfileActions, dispatch),
      eventsActions: bindActionCreators(bindEventsActions, dispatch),
    }),
  )(GetPremium),
);
